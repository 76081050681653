import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CarteBlanche = () => (
  <Layout>
    <SEO title="Carte Blanche" description="Manifestation pacifique et artistique pour préserver les liens humains. Place Saint Sernin, Toulouse." />
    <section id="carte-blanche-banner">
            <h1>CARTE BLANCHE</h1>
    </section>

    <section className="main container">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/les-creations/">Les créations</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Carte Blanche</li>
        </ol>
        </nav>

        <h2>Présentation</h2>
        <p>Ouverte à toutes et tous, amateurs, professionnels et curieux. Peu importe votre style, toutes les danses sont les bienvenues et sans jugement de valeur.</p>
        <p>Il s’agit d’un moment de partage dans le respect du protocole sanitaire (gestes barrières, port du masque pour les observateurs et distance entre les participants).</p>
        <p>L’objectif est de réagir aux décisions gouvernementales de manière pacifiste et artistique et de permettre de continuer à promouvoir l’art sous toutes ses formes, et aussi de préserver les liens humains qui rendent le spectacle VIVANT !</p>
        <p>Vous pouvez décider d’observer, ou de réagir quand vous le souhaitez. Il vous est également possible de montrer un projet qui vous tient à coeur et de l’offrir à un public de passage.</p>
        <br />
        <p><strong>Lieu : </strong>Place St SERNIN, à Toulouse.</p>
        <p><strong>Heure : </strong>À partir de 16h00.</p>
        <p><strong>Quand : </strong>Du lundi au samedi.</p>
        <br />
        <div className="contact-bloc">
            <p><strong>Si vous souhaitez présenter un extrait d’une pièce ou un travail personnel contactez nous par mail ou téléphone :</strong></p>
            <a href="tel:+33638985255" className="col-6 phone">06.38.98.52.55</a>
            <a href="mailto:cieailesdecire@gmail.com" className="col-6 mail" >cieailesdecire@gmail.com</a>
        </div>

    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.046" height="136.58" viewBox="0 0 1921.046 136.58">
        <path id="Tracé_10" data-name="Tracé 10" d="M7960,1363c915.685-43.829,1394.665-76.561,1645.546-98.86C9881.02,1239.654,9880,1227.819,9880,1227.819" transform="translate(-7959.953 -1227.416)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
        <p>Parmi les artistes qui ont déjà participé aux cartes blanches, des jeunes lycéens, des artistes amateurs et professionnels :</p>
        <p>
            6 TAM, en feat avec Hominguest,<br />
            LADALL,<br />
            Clément RP,<br />
            les élèves en cycle 3 du conservatoire de Toulouse,<br />
            Antoine Beres pour une performance inédite,<br />
            Claire Lamothe, Alain Caillau,<br />
            et la suite est à venir...
        </p>
        <p>Et tous ces passants et anonymes qui soutiennent l’initiative.</p>
        <br />
        <p><strong>Nous les remercions tous et tenons à saluer leur engagement.</strong></p>
        <p>Merci à toute l’équipe de <a href="https://www.canalsud.net/spip.php?page=article&id_article=4042" target="_blank" rel="noreferrer">CANAL SUD</a> pour leur accueil et la promotion de l’évènement.</p>
        <p>Et bien sur la Cie Ailes de Cire.</p>
    </section>
  </Layout>
)

export default CarteBlanche
